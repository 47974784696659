import logo from '../../assets/images/logo.jpg'

const BusinessInfo = {
    name: "IT Gallery",
    fullName: "IT Gallery",
    dealer: "(Proprietor: Md. Ahsan Habib Sumon)",
    address: "Mareya Bazar, Boda, Panchagarh",
    mobile: "Mobile: 01303-556776,01730-506868",
    img: logo,
}

export default BusinessInfo;
