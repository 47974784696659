import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import ViewInvoice from './ViewInvoice';
import ViewInvoice2 from './ViewInvoice2';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updatePurchase } from '../../../../features/PurchaseSlice/PurchaseSlice';
import { toast } from 'react-toastify';
import usePaginationSearch from '../../../../hooks/usePaginationSearch';
import ReactPaginate from 'react-paginate';
import { updatePurchaseOrder } from '../../../../features/purchaseOrderSlice/purchaseOrderSlice';

const PurchaseOrderList = () => {
    const { items, pageCount, limit, pageNo, loading, handleSearchResult,
        handleLimit, handlePageClick } = usePaginationSearch('https://16ana.net/it-gallery/getpurchaseorderswithfilter')
    const userAc = useSelector((state) => state.user.user);
    const [purchaseModal, setPurchaseModal] = useState(null);
    const [purchaseModal2, setPurchaseModal2] = useState(null);
    const [updateModal, setUpdateModal] = useState(null);
    const dispatch = useDispatch();

    const handleAddProductToInvoice = (purchase) => {
        setPurchaseModal(purchase)


        const viewedUser = userAc?.email.split('@')[0]
        const currentUser = purchase?.user;

        if (viewedUser !== currentUser) {
            fetch(`https://16ana.net/it-gallery/getpurchaseorders/${purchase?._id}`, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ viewedUser }),
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                })
        }
    }
    const handleAddProductToInvoice2 = (purchase) => {
        setPurchaseModal2(purchase)


        const viewedUser = userAc?.email.split('@')[0]
        const currentUser = purchase?.user;

        if (viewedUser !== currentUser) {
            fetch(`https://16ana.net/it-gallery/getpurchaseorders/${purchase?._id}`, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ viewedUser }),
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                })
        }
    }
    const handleUpdatePurchase = (purchase) => {
        dispatch(updatePurchaseOrder(purchase));

    }
    const handleDeleteProduct = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://16ana.net/it-gallery/getpurchaseorders/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setTimeout(() => { window.location.reload(); }, 3000);
                })
        }
    };
    return (
        <div className='w-full px-2 pb-5 my-5'>
            <h1 className='text-lg text-center bg-red-600 p-3 text-white rounded-xl'>Manage Purchase Orders</h1>
            <div className='flex justify-between items-center my-4'>
                <div class="form-control w-28  max-w-xs lg:mr-2">
                    <label class="label">
                        <span class="label-text">Show</span>
                    </label>
                    <select onChange={handleLimit} defaultValue='' class="text-center text-xs rounded-lg text-black select select-bordered select-error w-full max-w-xs" required>
                        <option selected >20</option>
                        <option>10</option>
                        <option>50</option>
                        <option>100</option>
                        <option>All</option>
                    </select>
                </div>
                <div class="form-control w-60 mt-9">
                    <input onChange={handleSearchResult} type="text" placeholder="Search Purchase Order" class="input text-xs input-bordered bg-white input-error w-full max-w-xs" />
                </div>
            </div>
           <div className='overflow-auto'>
           <table class="table w-1/2 mx-auto text-xs">
                <thead className='text-center'>
                    <tr>
                        <th className='bg-red-600 text-white normal-case'>SL#</th>
                        <th className='bg-red-600 text-white normal-case'>Order Date</th>
                        <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                        <th className='bg-red-600 text-white normal-case'>Total Amount</th>
                        <th className='bg-red-600 text-white normal-case'>View and Print</th>
                        <th className='bg-red-600 text-white normal-case'>View and Print</th>
                        <th className='bg-red-600 text-white normal-case'>Added By</th>
                        {userAc.type === "Super Admin" && <th className='bg-red-600 text-white normal-case'>Reviewed</th>}
                        <th className='bg-red-600 text-white normal-case'>Action</th>
                        <th className='bg-red-600 text-white normal-case'>Action</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {
                        items?.map((purchase, index) => <tr className=''>
                            <td>{limit === "All" ? index + 1 : index + 1 + limit * (pageNo - 1)}</td>
                            <td>{purchase?.date}</td>
                            <td>{purchase?.invoice}</td>
                            <td>{purchase?.totalAmount}</td>
                            <td><label for="view-modal" onClick={() => handleAddProductToInvoice(purchase)} className={`btn  btn-xs btn-success text-white`}>Purchase Officer Copy</label></td>
                            <td><label for="view-modal-2" onClick={() => handleAddProductToInvoice2(purchase)} className={`btn btn-xs btn-info text-white`}>Supplier Copy</label></td>
                            <td><button className='btn btn-xs btn-primary text-white normal-case'>{purchase?.user ? purchase.user : "User"}</button></td>
                            {userAc.type === "Super Admin" && <td>{purchase?.viewedUser ? <button className='btn btn-xs btn-info text-white normal-case'>{purchase.viewedUser}</button> : <button className='btn btn-xs btn-warning text-white normal-case'>Not Viewed</button>}</td>}
                            {userAc?.editPurchase && <td><Link to={`/managepurchaseorder/edit/${purchase._id}`} onClick={() => handleUpdatePurchase(purchase)} className='btn btn-info btn-xs'><FaEdit /></Link></td>}
                            {userAc?.deletePurchase && <td><button onClick={() => handleDeleteProduct(purchase._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                        </tr>)
                    }


                </tbody>
            </table>
           </div>
            {limit === "All" ? "" : <div className='my-8'>
                <ReactPaginate
                    previousLabel={'Pre'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={'btn-group justify-center'}
                    pageClassName={'btn btn-error text-white'}
                    activeClassName={'btn bg-white text-black'}
                    previousClassName={'btn btn-error text-white'}
                    nextClassName={'btn btn-error text-white'}
                    breakClassName={'btn btn-error text-white'}></ReactPaginate>
            </div>}

            <div className='w-8/12'>
                {
                    purchaseModal && <ViewInvoice setPurchaseModal={setPurchaseModal} purchaseModal={purchaseModal}></ViewInvoice>
                }
            </div>
            <div className='w-8/12'>
                {
                    purchaseModal2 && <ViewInvoice2 setPurchaseModal2={setPurchaseModal2} purchaseModal2={purchaseModal2}></ViewInvoice2>
                }
            </div>
        </div>

    );
};

export default PurchaseOrderList;