import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import profitimg from '../../../../src/assets/images/profit.png'
import useFetchData from '../../../hooks/useFetchData';

const TodaySales = () => {
    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    const { items: profit } = useFetchData("https://16ana.net/it-gallery/daywiseprofit");

    const todayProfit= profit.filter(pf=>pf.day===today);
   

    return (
        <div class="mx-auto w-11/12 bg-coral text-black rounded-lg overflow-hidden shadow-lg glassmorphism">
            <div className='grid grid-cols-12'>
                <div class="col-span-5 w-full">
                    <img src={profitimg} alt="Card image" class="mx-auto my-auto h-28 w-28 md:h-full md:w-full rounded-full border-4 border-red-600 object-cover" />
                </div>
                <div class="col-span-6 my-auto">
                    <div class="text-lg font-semibold mb-2">Today Profit</div>
                    <p class="text-xl text-black">{todayProfit[0]?.profit?(todayProfit[0]?.profit).toFixed(2):0.00} TK</p>
                </div>
            </div>
        </div>
    );
};

export default TodaySales;