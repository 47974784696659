import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { toast } from 'react-toastify';
import Loader from '../../../Shared/Loader';
import logo from '../../../../assets/images/logo.jpg';
import { useReactToPrint } from 'react-to-print';
import BusinessInfo from '../../../Shared/BusinessInfo';

const ProductCustomerLedger = () => {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [sales, setSales] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [searchText, setSearchText] = useState();
    const [stockLedger, setStockLedger] = useState([]);
    const [opening, setOpening] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false)
    const [details, setDetails] = useState('');
    const [isFilter, setIsFilter] = useState(false);
    const componentRef = useRef();

    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    console.log(stockLedger);

    useEffect(() => {
        fetch(`https://16ana.net/it-gallery/getproducts`)
            .then(res => res.json())
            .then(data => {
                setProducts(data);
                // setLoading(false)
            })
    }, [])

    const handleFilteredData = e => {
        e.preventDefault();
        // const startDate = e.target.startDate.value;
        // const endDate = e.target.endDate.value;
        const code = e.target.code.value;

        // const options = {
        //     timeZone: 'Asia/Dhaka',
        //     hour12: false,
        // };
        // const currentTime = new Date().toLocaleString('en-US', options);

        const url = `https://16ana.net/it-gallery/getsaleforproduct?searchTerm=${code}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                console.log(data)
                setStockLedger(data);
                // setOpening(data.openingBalance);
                setIsFilter(true);
                setIsLoaded(true);
                // setDetails({ startDate: startDate, name: selectedProduct?.name, code: code, range: startDate + " to " + endDate, time: currentTime });
                // setLoading(false)
            })

    }

    // const totalPurchase = stockLedger.reduce((total, currentValue) => {
    //     if (currentValue.purchaseDate) {
    //         return total + parseInt(currentValue.quantity);
    //     } else {
    //         return total;
    //     }
    // }, 0);

    // const totalPurchase = stockLedger.reduce((total, currentValue) => {
    //     const quantity = parseFloat(currentValue.quantity);
    //     if (currentValue.purchaseDate || currentValue.salesReturnDate) {
    //         return total + quantity;
    //     } else {
    //         return total;
    //     }
    // }, 0);


    // const totalSales = stockLedger.reduce((total, currentValue) => {
    //     const quantity = parseFloat(currentValue.quantity);
    //     if (currentValue.salesDate || currentValue.purchaseReturnDate) {
    //         return total + quantity;
    //     } else {
    //         return total;
    //     }
    // }, 0);

    // if (loading) {
    //     return <Loader />
    // }
    const generatePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Bank Statement Of ${details?.accountName}`,

    })


    const handleSearchResult = (event) => {
        // setSearchText(event.target.value)
        const search = event.target.value;
        console.log(search);

        fetch(`https://16ana.net/it-gallery/getproductsofsale?searchTerm=${search}`)
            .then(res => res.json())
            .then(data => {
                console.log(data);
            })

        // const match = products.filter(product => product.name.includes(searchText) ||
        //     product.name.toLowerCase().includes(searchText) || product.name.toUpperCase().includes(searchText));
        // setSearchResult(match);
        setIsSearching(true);
    }

    const handleAddProductToInvoice = (product) => {
        setSelectedProduct(product);
        setIsSearching(false)
        setSearchText('');
    }


    return (
        <div className='mx-2'>
            <h2 className='mt-8 text-center text-lg font-bold'>Product Wise Invoice</h2>
            <div className='flex flex-col lg:flex-row lg:justify-between lg:items-center'>
                {/* <div className='lg:mt-28 mt-8 mr-2'>
                    <div className='flex flex-col lg:flex-row justify-between lg:items-center'>
                        <div class="form-control w-80 lg:w-40 mx-auto">
                            <input onChange={handleSearchResult} value={searchText} type="text" placeholder="Search Product" class="input text-xs input-bordered border-dark-purple w-80 mx-auto lg:w-60 max-w-xs" />
                        </div>
                    </div>
                    <div style={isSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 mx-auto gap-2 bg-gray-200 p-2 ${isSearching ? 'h-60' : "h-8"} w-80 overflow-auto`}>
                        {
                            isSearching ? searchResult.map(product =>
                                <label for="update-modal" onClick={() => handleAddProductToInvoice(product)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer normal-case`}>{product?.name}</label>) :
                                products.map(product =>
                                    <label for="update-modal" onClick={() => handleAddProductToInvoice(product)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer normal-case`}>{product?.name}</label>)
                        }
                    </div>
                </div> */}
                <div className='flex justify-center items-center lg:mr-20'>
                    <form onSubmit={handleFilteredData} className='mt-12 flex flex-col lg:flex-row justify-center items-center'>
                        {/* <div class="form-control w-full mx-auto lg:w-40 max-w-xs lg:mr-2">
                            <label class="label">
                                <span class="label-text">Starting Date</span>
                            </label>
                            <input name='startDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full mx-auto lg:w-40 max-w-xs lg:mr-2">
                            <label class="label">
                                <span class="label-text">End Date</span>
                            </label>
                            <input name='endDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                        </div> */}
                        <div class="form-control w-80 lg:w-40 max-w-xs">
                            <label class="label">
                                <span class="label-text">Product Code</span>
                            </label>
                            <div class="form-control w-80 lg:w-40">
                                <input name='code' type="text" defaultValue={selectedProduct?.code} placeholder="Product Code" class="input text-xs input-bordered border-dark-purple w-full lg:w-40 max-w-xs" />
                            </div>
                        </div>
                        <input type='submit' value='Search' className='btn btn-md btn-error w-80 mx-auto lg:w-20 text-white mt-4 lg:mt-9 lg:ml-2' />
                    </form>
                </div>
            </div>
            {isLoaded && <div className='text-right'>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-error text-white btn-xs mt-2 lg:mt-0"
                    table="table-to-xls"
                    filename={`Customer- ${stockLedger?.salesCustomer}`}
                    sheet={`customer-${details.accountName}`}
                    buttonText="Export to exel" />
                <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button>
            </div>
            }
            {isLoaded && <div ref={componentRef}>
                <div className='flex justify-center items-center mt-8'>
                    <div>
                        <img src={BusinessInfo.img} className="w-20 lg:w-44 lg:mr-20 lg:mb-4" alt="" />
                    </div>
                    <div className='lg:mr-40'>
                        <h1 className='text-xl lg:text-4xl text-black text-center'>{BusinessInfo.fullName}</h1>
                        <h1 className='text-sm lg:text-xl text-black text-center'>{BusinessInfo.dealer}</h1>
                        <h1 className='text-xs text-black text-center'>{BusinessInfo.address}</h1>
                        <h1 className='text-xs lg:text-xl text-black text-center'>{BusinessInfo.mobile}</h1>
                    </div>
                </div>
                <div class="flex items-center justify-between">
                    <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                    <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                </div>
                <div class="flex items-center justify-between">
                    <hr class="border-b border-gray-300 w-full my-px" />
                    <hr class="border-b border-gray-300 w-full my-px" />
                </div>

                <h3 class="text-2xl uppercase text-center font-bold mt-8">Sales Invoice</h3>
                <div className='text-left ml-20 text-xs'>
                    <p><span style={{ marginRight: '31px' }} className='font-bold'>Invoice No:</span>{stockLedger?.salesInvoice}</p>
                    <p><span style={{ marginRight: '71px' }} className='font-bold'>Date:</span>  {stockLedger?.salesDate}</p>
                    <p><span className='font-bold mr-8'>Customer:</span>{stockLedger?.customerCode}-{stockLedger?.salesCustomer}</p>
                    <p><span className='font-bold mr-12'>Address:</span>{stockLedger?.salesAddress}</p>
                </div>
                <div className='overflow-auto'>
                    <table class="table w-1/2 mt-8 mx-auto text-xs">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>SL#</th>
                                <th className='bg-red-600 text-white normal-case'>Product Code</th>
                                <th className='bg-red-600 text-white normal-case'>Product Name</th>
                                <th className='bg-red-600 text-white normal-case'>Quantity</th>
                                <th className='bg-red-600 text-white normal-case'>Rate</th>
                                <th className='bg-red-600 text-white normal-case'>Total</th>
                                {/* {userAc?.showCostInInvoice && <th className='bg-red-600 text-white normal-case'>Cost</th>} */}
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                stockLedger?.products?.map((product, index) => <tr className=''>
                                    <td>{index + 1}</td>
                                    <td>{product.code}</td>
                                    <td>{product.name}</td>
                                    <td>{product.quantity}</td>
                                    <td>{product.rate}</td>
                                    <td >{product.total}</td>
                                    {/* {userAc?.showCostInInvoice && <td >{product.cost}</td>} */}
                                </tr>)
                            }

                        </tbody>
                        <tbody className='text-center bg-white'>
                            <tr className='border-t-2 border-black'>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td ></td>
                                <td >Total</td>
                                <td className='font-bold'>{stockLedger?.totalAmount}</td>
                                {/* {userAc?.showCostInInvoice && <td className='font-bold'>{totalCost}</td>} */}
                            </tr>
                            <tr className=''>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Discount</td>
                                <td className='font-bold'>{stockLedger?.discount}</td>
                            </tr>
                            <tr className=''>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className='font-bold'>Grand Amount</td>
                                {/* {userAc?.showCostInInvoice && <td className='font-bold'>{grandTotal}</td>} */}
                            </tr>
                            <tr className=''>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Payment</td>
                                <td className='font-bold'>{stockLedger?.payment}</td>
                            </tr>
                            <tr className=''>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Total Due</td>
                                <td className='font-bold'>{stockLedger?.dueAmount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='flex justify-between mt-40'>
                    <h3 className='border-t-2 border-gray-400 py-2 inline-block ml-40 font-bold text-sm'>Authorized Sign</h3>
                    <h3 className='border-t-2 border-gray-400 py-2 inline-block mr-40 font-bold text-sm'>Customer Sign</h3>
                </div>

            </div>}
        </div>
    );
};

export default ProductCustomerLedger;