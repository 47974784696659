import React, { useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { removeFromUpdateList, removeProductFromList } from '../../../../../features/PurchaseSlice/PurchaseSlice';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate, useParams } from 'react-router-dom';

const AddPurchaseInvoice = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { editId } = useParams();
    const { products } = useSelector((state) => state.purchaseInvoice.updatePurchase);
    const { details } = useSelector((state) => state.purchaseInvoice);
    const [isDiscount, setIsDiscount] = useState(true);
    const [discount, setDiscount] = useState('');
    const [payment, setPayment] = useState('');
    const [isPayment, setIsPayment] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    console.log(editId);
    const { updatePurchase } = useSelector((state) => state.purchaseInvoice);
    const removeFromList = (product) => {
        dispatch(removeFromUpdateList(product));
    }

    const totalAmount = updatePurchase.products?.reduce((total, currentValue) => total + parseInt(currentValue?.total), 0);

    const handleDiscount = (event) => {
        const discount = parseFloat(event?.target?.value);
        setDiscount(discount);
        setIsDiscount(true);
    }
    const handlePayment = (event) => {
        const payment = parseInt(event?.target?.value);
        setPayment(payment);
        setIsPayment(true);
        setIsDiscount(true);
    }

    const totalRef = useRef('');
    const discountRef = useRef('');
    const grandTotalRef = useRef();
    const paymentRef = useRef();
    const dueAmountRef = useRef();

    const handleSubmitPurchase = () => {
        const totalAmount = parseInt(totalRef.current.innerText);
        const discount = parseInt(discountRef.current.value);
        const grandTotal = parseInt(grandTotalRef.current.value);
        const paymentdata = parseInt(paymentRef.current.value);
        const payment = paymentdata ? parseInt(paymentRef.current.value) : 0;
        const dueAmount = parseInt(dueAmountRef.current.value);
        const user = userAc?.email.split('@')[0];
        const viewedUser = false;

        const data = { products: products, ...details, totalAmount, discount, grandTotal, payment, user, dueAmount, viewedUser }
        const url = `https://16ana.net/it-gallery/getpurchases/${editId}`
        fetch(url, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Purchase Updated Successfull')
                console.log(data);
                navigate("/managepurchase")
                setTimeout(() => { window.location.reload(); }, 2000);

            })

    }
    return (
        <div className='lg:grid lg:grid-cols-12 gap-2 h-screen'>
            <div className='overflow-auto lg:col-span-10 text-xs z-30 mb-4'>
                <div className=''>
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>SL#</th>
                                <th className='bg-red-600 text-white normal-case'>Product Code</th>
                                <th className='bg-red-600 text-white normal-case'>Product Name</th>
                                <th className='bg-red-600 text-white normal-case'>Quantity</th>
                                <th className='bg-red-600 text-white normal-case'>Rate</th>
                                <th className='bg-red-600 text-white normal-case'>Total</th>
                                <th className='bg-red-600 text-white normal-case'>Action</th>
                            </tr>
                        </thead>
                        <tbody className='text-center text-xs'>
                            {
                                updatePurchase?.products?.map((product, index) => <tr className='hover'>
                                    <td className='h-8'>{index + 1}</td>
                                    <td className='h-8'>{product?.code}</td>
                                    <td className='text-xs'>{(product?.name).replace(/\([^\)]+\)\s*$/, '')}</td>
                                    <td><input type="number" value={product?.quantity} placeholder="Type here" className="input text-xs input-bordered text-center h-8 w-20 max-w-lg" /></td>
                                    <td><input type="number" value={product?.rate} placeholder="Type here" className="input text-xs input-bordered text-center h-8 w-28 max-w-lg" /></td>
                                    <td><input type="number" value={product?.total} placeholder="Type here" className="input text-xs input-bordered text-center h-8 w-48 max-w-lg" /></td>
                                    <td> <button onClick={() => removeFromList(product)} className={`btn btn-sm btn-error cursor-pointer`}><AiOutlineClose /></button> </td>
                                </tr>)
                            }
                        </tbody>
                        <tfoot className='text-center h-2'>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Total</td>
                                <td ref={totalRef} className='text-sm'>{totalAmount}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Discount</td>
                                <td> <input ref={discountRef} onChange={handleDiscount} type="number" defaultValue={updatePurchase?.discount} placeholder="Type Discount" className="input text-sm input-bordered text-center w-48 h-8 mx-auto max-w-lg" /></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div className='overflow-auto lg:col-span-2 ml-20 lg:ml-0'>
                <div style={{ height: '400px' }} className='bg-red-600 w-48  text-xs text-center'>
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Grand Total</th>
                            </tr>
                        </thead>
                    </table>
                    <input ref={grandTotalRef} type="number" value={isDiscount === true ? totalAmount - discount : updatePurchase?.grandTotal} placeholder="Type here" className="input text-xs input-bordered text-center w-36 mx-auto max-w-lg" />
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Cash Payment</th>
                            </tr>
                        </thead>
                    </table>
                    <input ref={paymentRef} onChange={handlePayment} defaultValue={updatePurchase?.payment} type="number" placeholder="Type Payment" className="input text-xs input-bordered text-center w-36 mx-auto max-w-lg" />
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Due Amount</th>
                            </tr>
                            {/* ? updatePurchase?.dueAmount */}
                        </thead>
                    </table>
                    <input ref={dueAmountRef} type="number" value={isDiscount === false ? updatePurchase.dueAmount : isDiscount & isPayment === true ? (totalAmount - discount) - payment : (totalAmount - discount) - payment} placeholder="Type here" className="input text-xs input-bordered text-center w-36 mx-auto max-w-lg" />
                    <button onClick={handleSubmitPurchase} className='btn btn-sm mt-4 text-white'>Update Purchase</button>
                </div>
            </div>
        </div>
    );
};

export default AddPurchaseInvoice;