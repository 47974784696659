import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const api = createApi({
    reducerPath:'api',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://16ana.net/it-gallery/' }),
    tagTypes:[],
    endpoints: () => ({ }),
})

export default api;