import React, { useEffect, useState } from 'react';
import sale from '../../../../src/assets/images/sale.png'

const ThisMonthSales = () => {
    const [sales, setSales] = useState([]);

    let currentDate = new Date();

    // Get the current month and year as strings
    let monthName = currentDate.toLocaleString('default', { month: 'long' });
    let yearName = currentDate.toLocaleString('default', { year: 'numeric' });
    const month=monthName+" "+yearName
    useEffect(() => {
        fetch(`https://16ana.net/it-gallery/getmonthwisesales`)
            .then(res => res.json())
            .then(data => {
                const filtered=data.filter(sale=>sale.month===month);
                setSales(filtered[0]);
            }
            )
    }, [])


    return (
        <div class="mx-auto w-11/12 bg-coral text-black rounded-lg overflow-hidden shadow-lg glassmorphism">
            <div className='grid grid-cols-12'>
                <div class="col-span-5 w-full">
                    <img src={sale} alt="Card image" class="mx-auto my-auto h-28 w-28 md:h-full md:w-full rounded-full border-4 border-red-600 object-cover" />
                </div>
                <div class="col-span-6 my-auto">
                    <div class="text-lg font-semibold mb-2">This Month Sales</div>
                    <p class="text-xl text-black">{sales?.sales?(sales?.sales)?.toFixed(2):0} TK</p>

                </div>
            </div>
        </div>
    );
};

export default ThisMonthSales;