// import React, { useState } from 'react';
// import { useRef } from 'react';
// import { useForm } from 'react-hook-form';
// import { toast } from 'react-toastify';
// import { userKeyMapping } from '../../../Shared/UserPermissions'



// const UpdateModal = ({ updateUser, setUpdateUser }) => {
//     const { _id, email, password, name, type, status, ...permissions } = updateUser;
//     const addCustomKeysToObj = (userObj, keyMapping) => {
//         return Object.keys(userObj).reduce((acc, key) => {
//             const customKey = keyMapping[key] || key;
//             acc[customKey] = userObj[key];
//             return acc;
//         }, {});
//     };
//     const [userPermissions, setUserPermissions] = useState(addCustomKeysToObj(permissions, userKeyMapping));

//     console.log(userPermissions);

//     const onSubmit = () => {

//         fetch(`https://16ana.net/it-gallery/getusers/${_id}`, {
//             method: 'PUT',
//             headers: {
//                 'content-type': 'application/json'
//             },
//             body: JSON.stringify(userPermissions)
//         })
//             .then(res => res.json())
//             .then(data => {
//                 console.log(data);
//                 setUpdateUser(null);
//                 toast('User Upadated Successful');
//                 // window.location.reload(false)
//             })
//     }

//     const noEdit = () => {
//         window.location.reload(false)
//     }

//     const handleToggle = (key) => {
//         setUserPermissions((prevUserPermissions) => ({
//             ...prevUserPermissions,
//             [key]: !prevUserPermissions[key],
//         }));
//     };

//     return (
//         <div>
//             <input type="checkbox" id="update-modal" class="modal-toggle" />
//             <div class="modal mt-12">
//                 <div class="modal-box max-w-none w-8/12 relative">
//                     <label for="update-modal" onClick={() => noEdit()} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
//                     <h3 class="text-lg font-bold">Update {name} Permissions</h3>
//                     <div className="container mx-auto p-4 grid grid-cols-4">
//                         {Object.entries(userPermissions).map(([key, value]) => (
//                             <div
//                                 key={key}
//                                 className="mb-4 p-4 rounded-md shadow-md bg-white hover:shadow-lg transition-shadow"
//                             >
//                                 <label className="flex items-center">
//                                     <input
//                                         type="checkbox"
//                                         className="mr-2"
//                                         checked={value}
//                                         onChange={() => handleToggle(key)}
//                                     />
//                                     {key}
//                                 </label>
//                             </div>

//                         ))}
//                         <button onClick={() => onSubmit()} className='btn btn-error text-white ml-2 mt-2'>Update User Permissions</button>
//                     </div>
//                 </div>
//             </div>
//         </div >
//     );
// };

// export default UpdateModal;


import React, { useState } from 'react';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { userKeyMapping } from '../../../Shared/UserPermissions';

const UpdateModal = ({ updateUser, setUpdateUser }) => {
  const { _id, email, password, name, type, status, ...permissions } = updateUser;
  const [userPermissions, setUserPermissions] = useState(permissions);
  const userAc = useSelector((state) => state.user.user);

  const onSubmit = () => {
    const user = userAc?.email.split('@')[0];
    const approvedBy = false;
    fetch(`https://16ana.net/it-gallery/getusers/${_id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({...userPermissions,user,approvedBy}),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setUpdateUser(null);
        toast('User Updated Successfully');
        // window.location.reload(false)
      });
  };

  const noEdit = () => {
    window.location.reload(false);
  };

  const handleToggle = (key) => {
    setUserPermissions((prevUserPermissions) => ({
      ...prevUserPermissions,
      [key]: !prevUserPermissions[key],
    }));
  };

  return (
    <div>
      <input type="checkbox" id="update-modal" className="modal-toggle" />
      <div className="modal mt-12 lg:mt-12 mx-auto">
        <div className="modal-box max-w-none w-11/12 lg:w-8/12 lg:ml-60 relative">
          <label htmlFor="update-modal" onClick={() => noEdit()} className="btn btn-sm btn-circle absolute right-2 top-2">
            ✕
          </label>
          <h3 className="text-lg font-bold">Update {name} Permissions</h3>
          <div className="container mx-auto p-4 grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3">
            {Object.entries(userPermissions).map(([key, value]) => (
              <div key={key} className="mb-4 p-4 rounded-md shadow-md bg-white hover:shadow-lg transition-shadow">
                <label className="flex items-center">
                  <input type="checkbox" className="mr-2" checked={value} onChange={() => handleToggle(key)} />
                  {key} {/* Display the modified key here */}
                </label>
              </div>
            ))}
            <button onClick={() => onSubmit()} className="btn btn-error text-white ml-2 mt-px lg:mt-px">
              Update Permissions
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateModal;
