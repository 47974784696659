import { add, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { toast } from 'react-toastify';
import Loader from '../../../Shared/Loader';
import logo from '../../../../assets/images/logo.jpg';
import { useReactToPrint } from 'react-to-print';
import { useQuery } from 'react-query';
import BusinessInfo from '../../../Shared/BusinessInfo';

const BalanceSheet = () => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [balanceSheet, setBalanceSheet] = useState([]);
    const [opening, setOpening] = useState(0);
    const [additionalCapital, setAdditionalCapital] = useState(0);
    const [decoration, setDecoration] = useState(0);
    const [security, setSecurity] = useState(0);
    const [fixedAsset, setFixedAsset] = useState(0);
    const [loading, setLoading] = useState(true);

    console.log(decoration, security, fixedAsset);
    const componentRef = useRef();

    console.log(balanceSheet);

    const options = {
        timeZone: 'Asia/Dhaka',
        hour12: false,
    };
    const currentTime = new Date().toLocaleString('en-US', options);


    useEffect(() => {
        fetch('https://16ana.net/it-gallery/getbalancesheet')
            .then(res => res.json())
            .then(data => {
                setBalanceSheet(data);
                setLoading(false)
            })
    }, [])
    useEffect(() => {
        fetch('https://16ana.net/it-gallery/getopeningcapitals')
            .then(res => res.json())
            .then(data => {
                const openingCapital = parseInt(data[0].opening);
                setOpening(openingCapital);
            })
    }, [])
    useEffect(() => {
        fetch('https://16ana.net/it-gallery/getallincomes')
            .then(res => res.json())
            .then(data => {
                console.log(data);
                const additionalCapital = data.filter(capital => capital.name.includes('Additional Capital (Owner Given)'));
                const totalAdditionalCapital = additionalCapital.reduce((total, currentValue) => total + parseInt(currentValue.amount), 0);
                setAdditionalCapital(totalAdditionalCapital);
            })
    }, [])
    useEffect(() => {
        fetch('https://16ana.net/it-gallery/getallexpenses')
            .then(res => res.json())
            .then(data => {
                const decoration = data.filter(expense => expense.name.includes('Decoration Expense(Fixed)'));
                const totalDecoration = decoration.reduce((total, currentValue) => total + parseInt(currentValue.amount), 0);
                setDecoration(totalDecoration);

                const security = data.filter(expense => expense.name.includes('Security Expense(Fixed)'));
                const totalSecurity = security.reduce((total, currentValue) => total + parseInt(currentValue.amount), 0);
                setSecurity(totalSecurity);

                const fixedAsset = data.filter(expense => expense.name.includes('Asset Purchase(Fixed)'));
                const totalFixedAsset = fixedAsset.reduce((total, currentValue) => total + parseInt(currentValue.amount), 0);
                setFixedAsset(totalFixedAsset);
            })
    }, [])

    const generatePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Cash Statement`,

    });


    const profitLoss = (decoration + security + fixedAsset
        + balanceSheet?.totalStock + balanceSheet?.
            totalCustomerDue + balanceSheet?.bankBalance
        + balanceSheet?.cashBalance) - (opening + additionalCapital +
            balanceSheet?.totalSupplierDue + balanceSheet?.loanBalance);


    if (loading) {
        return <Loader />
    }

    return (
        <div className='w-full mx-auto'>
            <h2 className='mt-8 text-center text-xs font-bold'>Balance Sheet Report</h2>
            <div className='text-right'>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-error text-white btn-xs mt-2 lg:mt-0"
                    table="table-to-xls"
                    filename={`Balance Sheet`}
                    sheet={`balance-sheet`}
                    buttonText="Export to exel" />
                <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button>
            </div>
            <div ref={componentRef}>
                <div className='flex justify-center items-center mt-8'>
                    <div>
                        <img src={BusinessInfo.img} className="w-20 lg:w-44 lg:mr-20 lg:mb-4" alt="" />
                    </div>
                    <div className='lg:mr-40'>
                        <h1 className='text-xl lg:text-4xl text-black text-center'>{BusinessInfo.fullName}</h1>
                        <h1 className='text-sm lg:text-xl text-black text-center'>{BusinessInfo.dealer}</h1>
                        <h1 className='text-xs text-black text-center'>{BusinessInfo.address}</h1>
                        <h1 className='text-xs lg:text-xl text-black text-center'>{BusinessInfo.mobile}</h1>
                    </div>
                </div>
                <div class="flex items-center justify-between">
                    <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                    <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                </div>
                <div class="flex items-center justify-between">
                    <hr class="border-b border-gray-300 w-full my-px" />
                    <hr class="border-b border-gray-300 w-full my-px" />
                </div>
                <div className='text-left ml-20 mt-8 text-xs'>
                    <h4>Report Type: <span className='font-bold'>Balance Sheet</span></h4>
                    <h4>Report Date Range: All Time </h4>
                    <h4>Report Time: {currentTime}</h4>
                </div>
                <div className='mb-20'>
                    <div class="overflow-x-auto mt-8 w-10/12 mx-auto">
                        <table id='table-to-xls' class="min-w-full border border-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" class="bg-red-600 text-white py-3 px-6  text-left text-xs font-bold font-medium  uppercase tracking-wider border-b ">
                                        Liability
                                    </th>
                                    <th scope="col" class="bg-red-600 text-white py-3 px-6  text-center text-xs font-bold font-medium  uppercase tracking-wider border-b ">
                                        Amount
                                    </th>
                                    <th scope="col" class="bg-red-600 text-white py-3 px-6  text-center text-xs font-bold font-medium  uppercase tracking-wider border-b">
                                        Asset
                                    </th>
                                    <th scope="col" class="bg-red-600 text-white py-3 px-6  text-center text-xs font-bold font-medium  uppercase tracking-wider border-b">
                                        Amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr>
                                    <td class="py-4 px-6 whitespace-nowrap text-xs font-medium text-gray-900 border-b border-gray-300">
                                        Total Fixed Liability
                                    </td>
                                    <td class="py-4 px-6 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300 font-bold">
                                        {(opening + additionalCapital)?.toFixed(2)}
                                    </td>
                                    <td class="py-4 px-6 whitespace-nowrap text-xs font-medium text-gray-900 border-b border-gray-300">
                                        Total Fixed Asset
                                    </td>
                                    <td class="py-4 px-6 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300 font-bold">
                                        {(decoration + security + fixedAsset)?.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300">
                                        Capital Accounts (Opening)
                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">
                                        {(opening)?.toFixed(2)}
                                    </td>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300">
                                        Decoration Expense(Fixed)
                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">
                                        {(decoration)?.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300">
                                        Additional Capital (Owner Given)
                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">
                                        {(additionalCapital)?.toFixed(2)}
                                    </td>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300">
                                        Security Expense(Fixed)
                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">
                                        {(security)?.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300">

                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">

                                    </td>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300">
                                        Asset Purchase(Fixed)
                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">
                                        {(fixedAsset)?.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="py-4 px-6 whitespace-nowrap text-xs font-medium text-gray-900 border-b border-gray-300">
                                        Total Current Liability
                                    </td>
                                    <td class="py-4 px-6 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300 font-bold font-bold">
                                        {(profitLoss)?.toFixed(2)}
                                    </td>
                                    <td class="py-4 px-6 whitespace-nowrap text-xs font-medium text-gray-900 border-b border-gray-300">
                                        Total Current Asset
                                    </td>
                                    <td class="py-4 px-6 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300 font-bold">
                                        {(balanceSheet?.totalStock + balanceSheet?.
                                            totalCustomerDue + balanceSheet?.bankBalance
                                            + balanceSheet?.cashBalance - balanceSheet?.totalSupplierDue - balanceSheet?.loanBalance)?.toFixed(2)}
                                    </td>
                                </tr>
                                <tr className='text-center'>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300">
                                        Difference: Profit/Loss (Owner)
                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">
                                        {(profitLoss).toFixed(2)}
                                    </td>
                                    <td class="pl-8 py-2 whitespace-nowrap text-left text-xs text-gray-700 border-b border-gray-300">
                                        Total Stock Amount
                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">
                                        {(balanceSheet?.totalStock)?.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300">

                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">

                                    </td>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300">
                                        Customer Receivable
                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">
                                        {(balanceSheet.totalCustomerDue)?.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300">

                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">

                                    </td>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300">
                                        Bank Balance
                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">
                                        {(balanceSheet?.bankBalance)?.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300">

                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">

                                    </td>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300">
                                        Hand Cash Balance
                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">
                                        {(balanceSheet?.cashBalance)?.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300 font-bold">

                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">

                                    </td>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300">
                                        (-)Supplier Payable
                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">
                                        {(balanceSheet?.totalSupplierDue)?.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300 font-bold">
                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">
                                    </td>
                                    <td class="pl-8 py-2 whitespace-nowrap text-xs text-left text-gray-700 border-b border-gray-300">
                                        (-)Total Loan Payable
                                    </td>
                                    <td class="py-2 whitespace-nowrap text-center text-xs text-gray-500 border-b border-gray-300">
                                        {(balanceSheet.loanBalance)?.toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="py-4 px-6 whitespace-nowrap text-xs font-medium text-gray-900 font-bold text-xs">
                                        Total Liabilities
                                    </td>
                                    <td class="py-4 px-6 whitespace-nowrap text-center text-xs text-gray-500 font-bold text-xs">
                                        {(opening + additionalCapital + profitLoss)?.toFixed(2)}
                                    </td>
                                    <td class="py-4 px-6 whitespace-nowrap text-xs font-medium text-gray-900 font-bold text-xs">
                                        Total Assets
                                    </td>
                                    <td class="py-4 px-6 whitespace-nowrap text-center text-xs text-gray-500 font-bold text-xs">
                                        {(decoration + security + fixedAsset +
                                            balanceSheet?.totalStock + balanceSheet?.
                                                totalCustomerDue + balanceSheet?.bankBalance
                                            + balanceSheet?.cashBalance - balanceSheet?.totalSupplierDue - balanceSheet?.loanBalance)?.toFixed(2)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>



                        {/* <table id='table-to-xls' class="table w-full text-center shadow-xl rounded-lg">
                            <thead>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>SL</th>
                                    <th className='bg-red-600 text-white normal-case'>Date</th>
                                    <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                                    <th className='bg-red-600 text-white normal-case'>Name</th>
                                    <th className='bg-red-600 text-white normal-case'>Type</th>
                                    <th className='bg-red-600 text-white normal-case'>Deposit Amount</th>
                                    <th className='bg-red-600 text-white normal-case'>Withdraw Amount</th>
                                    <th className='bg-red-600 text-white normal-case'>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                        <td className='border border-red-600'></td>
                                        <td className='border border-red-600'></td>
                                        <td className='border border-red-600'></td>
                                        <td className='border border-red-600'></td>
                                        <td className='border border-red-600'></td>
                                        <td className='border border-red-600'></td>
                                        <td className='border border-red-600'></td>
                                        <td className='border border-red-600'></td>
                                    </tr>
                                
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white normal-case'></th>
                                    <th className='bg-red-600 text-white text-xs'></th>
                                    <th className='bg-red-600 text-white text-xs'></th>
                                    <th className='bg-red-600 text-white text-xs'></th>
                                    <th className='bg-red-600 text-white text-xs'></th>
                                    <th className='bg-red-600 text-white text-xs'></th>
                                </tr>
                            </tfoot>
                        </table> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BalanceSheet;